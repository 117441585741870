<template>
  <div style="margin-top: -5px">
    <b-card no-body class="mb-0 table-no-top table-text-nowrap">
      <b-table
        ref="refProfileListTable"
        class="position-relative mb-25 overflow-auto last-child-nb table-bottom-round table-small table-alternate-bg"
        :items="fetchStudentSections"
        responsive
        :fields="sectionFields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Course -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center" style="gap: 0.625rem" v-if="data.item.course">
            <b-avatar
              size="36"
              :src="data.item.course.avatar"
              :text="avatarText(data.item.course.name)"
              :variant="`light-info`"
              :to="{ name: 'apps-roster-course-view', params: { id: data.item.course.id } }"
              style="margin-top: 2px"
              rounded
            />
            <div class="d-flex flex-column" style="gap: 0">
              <b-link
                :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                class="font-weight-bold text-nowrap text-white mb-0"
              >
                {{ resolveCourseSectionName(data.item) }}
              </b-link>
              <b-link
                :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                class="d-flex align-items-center text-muted text-nowrap"
              >
                <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 1px" />
                <small> {{ data.item.course.name }}</small>
              </b-link>
            </div>
          </div>
        </template>

        <!-- Column: Attributes - Program -->
        <template #cell(fundingSource)="data">
          <b-badge :variant="`light-${resolveProfileProgramVariant(data.item.profile.attributes.fundingSource)}`" class="text-capitalize">
            {{ title(data.item.profile.attributes.fundingSource) }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`${resolveProfileSectionStatusVariant(sectionField(data.item.profile, data.item.id, 'status'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'status')) }}
          </b-badge>
        </template>

        <!-- Column: Transfer of Credit -->
        <template #cell(toc)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(data.item.profile.toc ? data.item.profile.toc.enabled : 'no')}`"
            class="text-capitalize"
          >
            {{ title(data.item.profile.toc && data.item.profile.toc.enabled ? 'Yes' : 'No') }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Voucher -->
        <template #cell(examVoucher)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(sectionField(data.item.profile, data.item.id, 'examVoucher'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'examVoucher')) }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Taken? -->
        <template #cell(examTaken)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(sectionField(data.item.profile, data.item.id, 'examTaken'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'examTaken')) }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Passed? -->
        <template #cell(examPassed)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(sectionField(data.item.profile, data.item.id, 'examPassed'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'examPassed')) }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Passed? -->
        <template #cell(labs)="data">
          <b-badge
            :variant="`light-${resolveProfileYNVariant(sectionField(data.item.profile, data.item.id, 'labCompleted'))}`"
            class="text-capitalize"
          >
            {{ title(sectionField(data.item.profile, data.item.id, 'labCompleted')) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Payment Status -->
        <!-- <template #cell(paymentStatus)="data">
  <b-badge :variant="`${resolveProfilePaymentStatusVariant(data.item.profile.attributes.paymentStatus)}`" class="text-capitalize">
    {{ title(data.item.profile.attributes.paymentStatus) }}
  </b-badge>
</template> -->

        <!-- Column: Attributes - Uploaded Resume -->
        <template #cell(uploadedResume)="data">
          <div class="d-flex align-items-center">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-resume-icon`"
              icon="UploadIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="startUpload(data.item, 'enrollment', 'resume')"
            />

            <b-tooltip title="Upload Resume" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-resume-icon`" />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.profile.attributes.uploadedResume)}`" class="text-capitalize">
              {{ title(data.item.profile.attributes.uploadedResume) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - Uploaded COE -->
        <template #cell(uploadedCoe)="data">
          <div class="d-flex align-items-center">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-coe-icon`"
              icon="UploadIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="startUpload(data.item, 'enrollment', 'coe')"
            />

            <b-tooltip title="Upload COE" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-coe-icon`" />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.profile.attributes.uploadedCoe)}`" class="text-capitalize">
              {{ title(data.item.profile.attributes.uploadedCoe) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - Uploaded Transcript -->
        <template #cell(uploadedTranscript)="data">
          <div class="d-flex align-items-center">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-transcript-icon`"
              icon="UploadIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="startUpload(data.item, 'transcript', 'prior')"
            />

            <b-tooltip title="Upload Transcript" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-transcript-icon`" />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.profile.attributes.uploadedTranscript)}`" class="text-capitalize">
              {{ title(data.item.profile.attributes.uploadedTranscript) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - VA Submission -->
        <template #cell(submittedToVa)="data">
          <div class="d-flex align-items-center">
            <feather-icon
              :id="`profile-row-${data.item.id}-update-submission`"
              icon="CheckCircleIcon"
              class="cursor-pointer mr-25"
              size="16"
              @click="markProfileAsSubmitted(data.item)"
              v-if="data.item.profile.attributes.submittedToVa === 'no'"
            />

            <b-tooltip
              title="Mark as Submitted"
              class="cursor-pointer"
              :target="`profile-row-${data.item.id}-update-submission`"
              v-if="data.item.profile.attributes.submittedToVa === 'no'"
            />

            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.profile.attributes.submittedToVa)}`" class="text-capitalize">
              {{ title(data.item.profile.attributes.submittedToVa) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Total Students -->
        <template #cell(sessionDate)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ moment(data.item.sessionDate).format('L') }}
            </span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import { avatarText, title } from '@core/utils/filter';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import studentStoreModule from '@/store/student/studentStoreModule';
import moment from 'moment';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';
import { momentTzFormatted } from '@/libs/utils/time';
import { hexToRGB } from '@/libs/utils/color';
import useProfiles from '@/views/apps/roster/profile/useProfiles';
import VueContext from 'vue-context';
import vSelect from 'vue-select';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    VueContext,
    vSelect,
  },
  props: {
    studentId: {
      type: String,
      required: true,
    },
    profileId: {
      type: String,
      required: true,
    },
  },
  methods: {
    sectionField(profile, section, field) {
      const sectionId = section;

      if (!profile.sections || !profile.sections[sectionId]) {
        return 'na';
      }

      return profile.sections[sectionId][field];
    },
  },
  setup(props) {
    const sectionFields = ref([
      // { key: 'name', label: 'Section Info', sortable: false },
      { key: 'status', sortable: false },
      { key: 'toc', label: 'ToC', sortable: false },
      { key: 'examVoucher', sortable: false },
      { key: 'examTaken', sortable: false },
      { key: 'examPassed', sortable: false },
      { key: 'labs', sortable: false },
      // { key: 'actions' },
    ]);
    const sectionsData = ref({});
    const currentPage = ref(1);
    const perPage = ref(100);
    const totalSections = ref(0);
    const totalPageCount = ref(1);

    const isSortDirDesc = ref(false);
    const sortBy = ref('startDate');

    const fetchStudentSections = (ctx) => {
      const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
      const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

      const promise = store.dispatch('studentStoreModule/fetchStudentSections', {
        id: props.studentId,
        queryParams: {
          limit: perPage.value,
          page: currentPage.value,
          populate: 'instructor course',
          sortBy: sb,
          profileIds: [props.profileId],
        },
      });

      return promise
        .then((response) => {
          const { results, totalResults, totalPages } = response.data;

          const resultsMapped = results.map((row, index) => {
            row.index = index + 1;
            return row;
          });

          sectionsData.value = resultsMapped;
          totalSections.value = totalResults;
          totalPageCount.value = totalPages;
          return resultsMapped;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            sectionsData.value = undefined;
          }
        });
    };

    const resolveCourseStatusVariant = (status) => {
      if (status === 'created') return 'secondary';
      if (status === 'enrolling') return 'info';
      if (status === 'waiting') return 'warning';
      if (status === 'live') return 'info';
      if (status === 'completed') return 'success';
      return 'info';
    };

    const resolveCourseSectionStartDate = (courseSection) => {
      return momentTzFormatted(courseSection.startDate, 'MMMM Do YYYY');
    };

    const {
      perPageOptions,
      resolveUserRoleVariant,
      resolveCourseSectionName,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionStatusIcon,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionVisibilityIcon,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageIcon,
      resolveCourseSectionDateMessageVariant,
    } = useCourseSections();

    const {
      resolveProfileVisibilityVariant,
      resolveProfileStatusVariant,
      resolveProfileSectionStatusVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileProgramVariant,
      resolveProfileYNVariant,
    } = useProfiles();

    return {
      fetchStudentSections,
      sectionFields,
      sectionsData,
      avatarText,
      title,
      moment,
      resolveCourseStatusVariant,

      resolveUserRoleVariant,
      resolveCourseSectionName,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionStatusIcon,
      resolveCourseSectionVisibilityIcon,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageIcon,
      resolveCourseSectionDateMessageVariant,
      resolveCourseSectionStartDate,

      perPageOptions,

      resolveProfileVisibilityVariant,
      resolveProfileStatusVariant,
      resolveProfileSectionStatusVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileProgramVariant,
      resolveProfileYNVariant,

      sortBy,
      isSortDirDesc,
      currentPage,
      perPage,
      totalSections,
      totalPageCount,
    };
  },
};
</script>
