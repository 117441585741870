<template>
  <!-- <b-sidebar
    id="add-new-class-sidebar"
    :visible="editClassActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:edit-class-active', val)"
  >
  </b-sidebar> -->

  <b-modal
    ref="modalUpsertTemplate"
    :visible="editClassActive"
    @hidden="closeModal"
    :busy="busy"
    size="lg"
    :title="`Edit Class`"
    ok-title="Save Changes"
    @ok="saveChanges"
    body-class="pt-0"
    centered
  >
    <!-- BODY -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-row>
        <b-col cols="12" md="8">
          <!-- Class Date -->
          <b-form-group label="Dates" label-for="class-dates">
            <flat-pickr
              id="class-dates"
              class="w-100 form-control"
              :config="{ altInput: true, altFormat: 'l, F j, Y', dateFormat: 'Z' }"
              v-model="classData.startTime"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" xl="4">
          <!-- Class Type -->
          <validation-provider #default="validationContext" name="Class Type" rules="required">
            <b-form-group label="Type" label-for="class-type" :state="getValidationState(validationContext)">
              <v-select
                v-model="classData.type"
                :options="classTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="class-type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6">
          <!-- Class Time Start -->
          <validation-provider #default="validationContext" name="Start Time" rules="required">
            <b-form-group label="Start Time" label-for="class-start-time" :state="getValidationState(validationContext)">
              <b-form-timepicker v-model="classData.timeStart" locale="en" input-id="class-start-time" />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <!-- Class Time End -->
          <validation-provider #default="validationContext" name="End Time" rules="required">
            <b-form-group label="End Time" label-for="class-end-time" :state="getValidationState(validationContext)">
              <b-form-timepicker v-model="classData.timeEnd" locale="en" input-id="class-start-time" />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6">
          <!-- Location -->
          <validation-provider #default="validationContext" name="Location" rules="required">
            <b-form-group label="Location" label-for="location" :state="getValidationState(validationContext)">
              <v-select
                v-model="classData.location"
                :options="locationOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="class-location"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6">
          <!-- Visibility -->
          <validation-provider #default="validationContext" name="Visibility" rules="required">
            <b-form-group label="Visibility" label-for="visibility" :state="getValidationState(validationContext)">
              <v-select
                v-model="classData.visibility"
                :options="visibilityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="class-visibility"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6" xl="4">
          <b-form-group label="Zoom Meeting ID (override)" label-for="status">
            <b-form-input v-model="classData.zoomMeetingId" placeholder="Override Zoom Meeting" type="text" />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormTimepicker,
  BFormDatepicker,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BRow,
  BModal,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, emit } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import countries from '@/libs/data/countries';
import store from '@/store';
import useClassesList from './useClassesList';
import flatPickr from 'vue-flatpickr-component';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { momentTz } from '@/libs/utils/time';

export default {
  components: {
    BSidebar,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    editClassActive: {
      type: Boolean,
      required: false,
    },
    editClassData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    saveChanges(event) {
      event.preventDefault();

      if (!this.classData.startTime) {
        return;
      }

      const date = this.classData.startTime;
      const classDate = momentTz(date).startOf('day').toDate();
      const startTime = momentTz(date).startOf('day').toDate();
      const endTime = momentTz(date).startOf('day').toDate();

      const [startHour, startMin, startSec] = this.classData.timeStart.split(':').map(Number);
      const [endHour, endMin, endSec] = this.classData.timeEnd.split(':').map(Number);

      startTime.setHours(startHour, startMin, startSec);
      endTime.setHours(endHour, endMin, endSec);

      const pl = {
        ...this.classData,
        dates: undefined,
        timeEnd: undefined,
        timeStart: undefined,
        date: undefined,
        rooms: undefined,
        startTime,
        endTime,
      };

      store
        .dispatch('rosterStoreModule/updateClass', {
          id: pl.id,
          classData: { ...pl },
        })
        .then(() => {
          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Course Cohort Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit course cohort',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const startTime = momentTz(props.editClassData.startTime).format('HH:mm:ss');
    const endTime = momentTz(props.editClassData.endTime).format('HH:mm:ss');

    const classData = ref({
      ...props.editClassData,
      timeStart: startTime,
      timeEnd: endTime,
    });

    const busy = ref(false);

    const resetClassData = () => {
      classData.value = { ...props.editClassData };
    };

    const closeModal = () => {
      busy.value = false;
      emit('update:edit-class-active', false);
      emit('update:edit-class-active', false);
      emit('update:edit-class-data', {});
      resetClassData();
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetClassData);

    const { statusOptions, visibilityOptions, classTypeOptions, locationOptions } = useClassesList();

    return {
      busy,

      classData,
      closeModal,

      refFormObserver,
      getValidationState,
      resetForm,

      //
      statusOptions,
      visibilityOptions,
      classTypeOptions,
      locationOptions,
    };
  },
};
</script>

<style lang="scss">
#add-new-class-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
