<template>
  <div>
    <b-card v-if="userData && userData.id" no-body class="border d-flex w-100 mb-0">
      <b-card-header class="d-flex flex-row justify-content-between align-items-start pt-100 pb-75 border-bottom">
        <div>
          <h5 class="mb-0">
            {{ userData.name }}
          </h5>
          <b-badge variant="light-info text-capitalize" style="margin-top: 0.2rem"> Instructor </b-badge>
        </div>

        <div>
          <b-button class="" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="unassignInstructor">
            Unassign
          </b-button>
        </div>
      </b-card-header>

      <b-card-body class="d-flex flex-column justify-content-center pb-0">
        <table class="w-100">
          <tr>
            <th class="pb-50">
              <span>Phone #</span>
            </th>

            <td class="pb-50">
              {{ userData.phoneNumber }}
            </td>
          </tr>
          <tr>
            <th>
              <span>Email</span>
            </th>
            <td>
              {{ userData.email }}
            </td>
          </tr>
        </table>
      </b-card-body>
    </b-card>
    <b-card v-else no-body class="mb-0 w-100">
      <b-card-header>
        <h5 class="mb-0">No Instructor Assigned</h5>
      </b-card-header>
      <b-card-body class="d-flex flex-column justify-content-between">
        <vue-autosuggest
          ref="autocomplete"
          v-model="instructorQuery"
          :suggestions="instructorSuggestions"
          :input-props="instructorInputProps"
          :section-configs="instructorSectionConfigs"
          :render-suggestion="instructorRenderSuggestion"
          @input="instructorFetchResults"
        />

        <b-button @click="doSelectInstructor" variant="primary" class="mt-1" block>Assign Instructor</b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton, BAvatar, BRow, BLink, BMedia } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { onUnmounted, ref } from '@vue/composition-api';
import store from '@/store';
import userStoreModule from '@/store/user/userStoreModule';
import router from '@/router';
import useCourseSections from '../useCourseSections';
import { avatarText } from '@core/utils/filter';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BAvatar,
    BRow,
    BLink,
    BMedia,
    VueAutosuggest,
  },
  props: {
    instructor: {
      type: Object,
      required: false,
      default: null,
    },
    courseSectionData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.instructorFetchResults();
  },
  methods: {
    instructorFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const usersPromise = this.$store.dispatch('userStoreModule/fetchUsers', {
          search: this.instructorQuery,
        });

        Promise.all([usersPromise]).then((values) => {
          this.instructorSuggestions = [];

          const users = values[0].data.results;

          users.length && this.instructorSuggestions.push({ name: 'users', data: users });
        });
      }, 250);
    },
    instructorRenderSuggestion(suggestion) {
      const user = suggestion.item;
      return (
        <div class="d-flex">
          <div>
            <b-avatar src={user.avatar} text={avatarText(user.name)} class="mr-50" variant="light-info" size="30" />
          </div>
          <div class="d-flex align-items-center">
            <h4 class="font-weight-normal mb-0">{user.name}</h4>
          </div>
        </div>
      );
    },
    async doSelectInstructor() {
      if (!this.instructorSelected) {
        return;
      }

      console.log(this.courseSectionData);

      this.$store
        .dispatch('rosterStoreModule/updateCourseSection', {
          courseSectionId: this.courseSectionData.id,
          courseSectionData: {
            instructor: this.instructorSelected.id,
          },
        })
        .then((response) => {
          console.log(`updated course`, response);
          this.$emit('refetch-data');
        })
        .catch((e) => {
          console.error(`failed to update course`, e);
        });
    },
    unassignInstructor() {
      this.$store
        .dispatch('rosterStoreModule/updateCourseSection', {
          courseSectionId: this.$route.params.id,
          courseSectionData: { instructor: null },
        })
        .then((response) => {
          console.log(`updated course`, response);
          this.$emit('refetch-data');
        })
        .catch((e) => {
          console.error(`failed to update course`, e);
        });
    },
  },
  watch: {
    '$props.instructor'(val) {
      console.log(this.$props.instructor);
      this.userData = this.$props.instructor;
    },
  },
  setup(props) {
    const userData = ref(null);

    if (props.instructor) {
      userData.value = props.instructor;
    }

    // store
    //   .dispatch('userStoreModule/fetchUser', { id: props.userId })
    //   .then((response) => {
    //     userData.value = response.data;
    //   })
    //   .catch((error) => {
    //     if (error.response.status === 404) {
    //       userData.value = undefined;
    //     }
    //   });

    const { resolveUserRoleVariant } = useCourseSections();

    const instructorQuery = ref('');
    const instructorSuggestions = ref([]);
    const instructorSelected = ref(undefined);
    const instructorSectionConfigs = {
      users: {
        limit: 5,
        onSelected: (selected) => {
          instructorSelected.value = selected.item;
        },
      },
    };

    const instructorInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for users',
      class: 'form-control',
      name: 'ajax',
    };

    return {
      userData,

      avatarText,
      resolveUserRoleVariant,

      instructorQuery,
      instructorSuggestions,
      instructorSectionConfigs,
      instructorInputProps,
      instructorSelected,
    };
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
