<template>
  <!-- <b-sidebar
    id="add-new-class-sidebar"
    :visible="createClassActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:create-class-active', val)"
  >
  </b-sidebar> -->

  <b-modal
    ref="modalUpsertTemplate"
    :visible="createClassActive"
    @hidden="closeModal"
    :busy="busy"
    size="lg"
    :title="`Create New Class`"
    ok-title="Create Class"
    @ok="createEntity"
    centered
  >
    <!-- BODY -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-row>
        <b-col cols="12" md="8">
          <!-- Class Date -->
          <b-form-group label="Dates" label-for="class-dates">
            <flat-pickr
              id="class-dates"
              class="w-100 form-control"
              :config="{ mode: 'multiple', altInput: true, altFormat: 'M j, Y', dateFormat: 'Z' }"
              :value="classData.$dates"
              @on-change="selectDates"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" xl="4">
          <!-- Class Type -->
          <validation-provider #default="validationContext" name="Class Type" rules="required">
            <b-form-group label="Type" label-for="class-type" :state="getValidationState(validationContext)">
              <v-select
                v-model="classData.type"
                :options="classTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="class-type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6">
          <!-- Class Time Start -->
          <validation-provider #default="validationContext" name="Start Time" rules="required">
            <b-form-group label="Start Time" label-for="class-start-time" :state="getValidationState(validationContext)">
              <b-form-timepicker v-model="classData.timeStart" locale="en" input-id="class-start-time" />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <!-- Class Time End -->
          <validation-provider #default="validationContext" name="End Time" rules="required">
            <b-form-group label="End Time" label-for="class-end-time" :state="getValidationState(validationContext)">
              <b-form-timepicker v-model="classData.timeEnd" locale="en" input-id="class-start-time" />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6">
          <!-- Location -->
          <validation-provider #default="validationContext" name="Location" rules="required">
            <b-form-group label="Location" label-for="location" :state="getValidationState(validationContext)">
              <v-select
                v-model="classData.location"
                :options="locationOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="class-location"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6">
          <!-- Visibility -->
          <validation-provider #default="validationContext" name="Visibility" rules="required">
            <b-form-group label="Visibility" label-for="visibility" :state="getValidationState(validationContext)">
              <v-select
                v-model="classData.visibility"
                :options="visibilityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="class-visibility"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- <b-col cols="12" md="6" xl="3">
          <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group label="Status" label-for="status" :state="getValidationState(validationContext)">
              <v-select
                v-model="classData.status"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="class-status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col> -->
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormTimepicker,
  BFormDatepicker,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BRow,
  BModal,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, emit } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import store from '@/store';
import useClassesList from './useClassesList';
import flatPickr from 'vue-flatpickr-component';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';

export default {
  components: {
    BSidebar,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    createClassActive: {
      type: Boolean,
      required: false,
    },
    sectionId: {
      type: String,
      required: false,
    },
  },
  methods: {
    selectDates(dates) {
      if (typeof dates === 'string') {
        this.classData.dates = dates.split(',');
      } else {
        this.classData.dates = dates;
      }
      console.log(this.classData);
    },
    async createEntity(event) {
      this.busy = true;
      event.preventDefault();

      if (!this.classData.dates || this.classData.dates.length === 0) {
        this.busy = false;
        return;
      }

      // Collect all promises from createClass calls
      const classCreationPromises = this.classData.dates.map(async (date) => {
        return this.createClass(date);
      });

      // Wait for all class creation promises to resolve
      await Promise.all(classCreationPromises);

      // Now that all classes are added, refresh UI and data
      this.busy = false;
      this.closeModal(); // Assuming you want to close the modal after creation
      this.$emit('refetch-data'); // Emit an event to refetch or refresh data

      // Optionally, you can show a success message here
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'All Classes Added Successfully!',
          icon: 'CheckIcon',
          variant: 'success',
        },
      });
    },
    async createClass(date) {
      const classDate = moment(date).startOf('day').toDate();
      const startTime = moment(date).startOf('day').toDate();
      const endTime = moment(date).startOf('day').toDate();

      console.log(startTime);
      console.log(endTime);

      const formatTime = (time) => moment(time, 'HH:mm:ss').format('HH:mm:ss');
      const [startHour, startMin, startSec] = formatTime(this.classData.timeStart).split(':').map(Number);
      const [endHour, endMin, endSec] = formatTime(this.classData.timeEnd).split(':').map(Number);

      startTime.setHours(startHour, startMin, startSec);
      endTime.setHours(endHour, endMin, endSec);

      const pl = {
        ...this.classData,
        dates: undefined,
        timeEnd: undefined,
        timeStart: undefined,
        rooms: undefined,
        startTime,
        endTime,
      };

      return store
        .dispatch('rosterStoreModule/addClass', {
          classData: { ...pl, sectionId: this.sectionId },
        })
        .then(() => {
          this.busy = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Course Cohort Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit course cohort',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  setup(props, { emit }) {
    const blankClassData = {
      // date: new Date().toISOString().slice(0, 10),
      timeStart: '09:00:00',
      timeEnd: '18:00:00',
      type: 'class',
      location: 'virtual',
      visibility: 'public',
      status: 'created',
    };

    const classData = ref({ ...blankClassData });
    const busy = ref(false);

    const resetClassData = () => {
      classData.value = { ...blankClassData };
    };

    const closeModal = () => {
      busy.value = false;
      emit('update:create-class-active', false);
      resetClassData();
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetClassData);

    const { statusOptions, visibilityOptions, classTypeOptions, locationOptions } = useClassesList();

    return {
      busy,

      classData,
      closeModal,

      refFormObserver,
      getValidationState,
      resetForm,

      //
      statusOptions,
      visibilityOptions,
      classTypeOptions,
      locationOptions,
    };
  },
};
</script>

<style lang="scss">
#add-new-class-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
