<template>
  <div class="container content-p">
    <!-- Alert: No item found -->

    <b-alert variant="danger" :show="courseSectionData === undefined">
      <h4 class="alert-heading">Error fetching course section data</h4>
      <div class="alert-body">
        No course section found with this course id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-course-section-list-pending' }"> Course Section List </b-link>
        for other courses.
      </div>
    </b-alert>

    <div v-if="courseSectionData" :key="courseSectionData.id">
      <course-section-edit-modal
        :edit-course-section-active.sync="editCourseSectionActive"
        :course-section-data.sync="courseSectionData"
        @refetch-data="refetchCourseSection"
      />

      <!-- Header -->
      <b-row class="mb-1">
        <b-col cols="12">
          <course-section-view-header
            :course-section-data.sync="courseSectionData"
            :edit-course-section-active.sync="editCourseSectionActive"
            @refetch-data="refetchCourseSection"
          />
        </b-col>
      </b-row>

      <!-- Student List-->
      <b-row class="mb-2">
        <b-col cols="12" lg="12" v-if="courseSectionData.status !== 'draft'">
          <!--- Tab Switcher-->
          <div class="d-flex justify-content-between align-items-center mb-1">
            <b-tabs nav-wrapper-class=" " nav-class="m-0" lazy>
              <b-tab title="Profiles" @click="changeTab('all')">
                <template #title>
                  <font-awesome-icon icon="fa-regular fa-code-merge" />
                  All Students
                </template>
              </b-tab>
              <b-tab title="Sections" @click="changeTab('section')">
                <template #title>
                  <lucide-icon name="Book" style="width: 15px; height: 15px" />
                  Direct Students
                </template>
              </b-tab>
            </b-tabs>
            <div></div>
          </div>

          <!--- Section Student List -->
          <div v-if="showingTab === 'all'">
            <course-section-view-students-list
              :section-id.sync="courseSectionData.id"
              :course-section-data.sync.sync="courseSectionData"
              v-if="$can('roster.section.student.list')"
              @refresh-section="() => refetchCourseSection()"
            />
            <h3 class="mb-0 mr-50" v-else>You are not authorized to view students</h3>
          </div>

          <div v-if="showingTab === 'section'">
            <!--- Cohort Student List -->
            <course-cohort-view-students-list
              :course-cohort-data.sync="courseSectionData.courseCohort"
              :course-section-data.sync.sync="courseSectionData"
              @refetch-data="refetchCourseSection"
              v-if="$can('roster.cohort.student.list')"
            />
            <h3 class="mb-0 mr-50" v-else>You are not authorized to view students</h3>
          </div>
        </b-col>
      </b-row>

      <b-row class="">
        <b-col cols="12" lg="4">
          <course-section-view-actions :course-section-data.sync="courseSectionData" @refetch-data="refetchCourseSection" class="mb-2" />

          <course-section-view-instructor
            @refetch-data="refetchCourseSection"
            :course-section-data.sync="courseSectionData"
            :instructor="courseSectionData.instructor"
          />
        </b-col>
        <b-col cols="12" lg="8">
          <class-list :section-id="courseSectionData.id" @refetch-data="refetchCourseSection"></class-list>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink, BButton, BCard, BCardHeader, BCardBody, BCardText, BTab, BTabs } from 'bootstrap-vue';
import CourseSectionViewInstructor from './CourseSectionViewInstructor.vue';
import CourseSectionViewActions from './CourseSectionViewActions.vue';
import CourseSectionViewStudentsList from './CourseSectionViewStudentsList.vue';
import CourseSectionViewHeader from './CourseSectionViewHeader';
import ClassList from '@/views/apps/roster/classes/list/ClassList.vue';
import CourseSectionEditModal from '../edit/CourseSectionEditModal.vue';
import CourseCohortViewStudentsList from '../../cohorts/view/CourseCohortViewStudentsList.vue';
import refreshCcw from 'lucide-vue/dist/esm/icons/refresh-ccw';

export default {
  computed: {
    refreshCcw() {
      return refreshCcw;
    },
  },
  components: {
    ClassList,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BTab,
    BTabs,

    // Local Components
    CourseSectionEditModal,
    CourseSectionViewHeader,
    CourseSectionViewStudentsList,
    CourseSectionViewActions,
    CourseSectionViewInstructor,
    CourseCohortViewStudentsList,
  },
  watch: {
    '$route.path'() {
      this.refetchCourseSection(this.$route.params.id);
    },
  },
  methods: {},
  mounted() {
    // this.refetchStudents();
    this.refetchCourseSection();
  },
  setup() {
    const showingTab = ref('all');
    const courseSectionData = ref(null);
    const editCourseSectionActive = ref(false);
    const fetchId = ref(router.currentRoute.params.id);

    const convertCohortToSection = (cohortId) => {
      store
        .dispatch('rosterStoreModule/fetchCourseCohort', { cohortId })
        .then((response) => {
          if (response.data.type === 'course') {
            fetchId.value = response.data.sections[0];
            return refetchCourseSection();
            return router.push(`/roster/courses/sections/${response.data.sections[0]}`);
          }
          courseSectionData.value = undefined;
        })
        .catch((error) => {
          courseSectionData.value = undefined;
        });
    };

    const refetchCourseSection = (id = fetchId.value) => {
      store
        .dispatch('rosterStoreModule/fetchCourseSectionSummary', { id })
        .then((response) => {
          courseSectionData.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.log('converting');
            return convertCohortToSection(id);
          }
          courseSectionData.value = undefined;
        });
    };

    const changeTab = (tab) => {
      showingTab.value = tab;
    };

    /*
       v-model="instructorQuery"
                  :suggestions="instructorSuggestions"
                  :input-props="instructorInputProps"
                  :section-configs="instructorSectionConfigs"
                  :render-suggestion="instructorRenderSuggestion"
                  :get-suggestion-value="instructorGetSuggestionValue"
                  @input="instructorFetchResults"
     */

    return {
      courseSectionData,
      showingTab,
      changeTab,
      editCourseSectionActive,
      refetchCourseSection,
    };
  },
};
</script>
