<template>
  <div>
    <AttendanceModal
      v-if="courseSectionData && isAttendanceOpen"
      :course-section-data.sync="courseSectionData"
      :is-table-open.sync="isAttendanceOpen"
    />

    <div class="d-flex align-items-center mb-75">
      <h3 class="mb-0 mr-50">Actions</h3>
    </div>

    <div class="grid">
      <div class="col">
        <div v-if="courseSectionData.status === 'draft'" class="row row-cols-2">
          <div class="pr-25">
            <b-button variant="info" @click="publishDraft" class="w-100" :disabled="isBtnBusy">
              <feather-icon icon="GlobeIcon" class="mr-25" />
              <span>Publish Draft</span>
            </b-button>
          </div>
        </div>

        <div v-else-if="courseSectionData.status !== 'draft'" class="row row-cols-2">
          <div v-if="$can('roster.attendance.update')" class="pl-25">
            <b-button :disabled="isBtnBusy" variant="primary" @click="takeClassAttendance" class="w-100">
              <feather-icon icon="CalendarIcon" class="mr-25" />
              <span>Take Attendance</span>
            </b-button>
          </div>
          <div v-if="$can('roster.attendance.update')" class="pl-25">
            <b-dropdown :disabled="isBtnBusy" class="mb-1" text="Download Attendance" variant="primary" block>
              <b-dropdown-item v-if="$can('roster.attendance.export')" @click="downloadAttendanceSheet('standard')">
                Direct Student Standard
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('roster.attendance.export')" @click="downloadAttendanceSheet('extended')">
                Direct Student Extended
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="$can('roster.section.export')" class="pl-25">
            <b-button :disabled="isBtnBusy" @click="downloadSectionExportSheet" class="mb-1" variant="primary" block>
              <feather-icon icon="DownloadIcon" class="mr-25" />
              Download Roster
            </b-button>
          </div>
          <div v-if="$can('roster.section.export')" class="pl-25">
            <b-button :disabled="isBtnBusy" @click="downloadSectionExportCompliance" class="mb-1" variant="primary" block>
              <feather-icon icon="DownloadIcon" class="mr-25" />
              Download Compliance
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BMedia,
  BAvatar,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BCardText,
  BFormInput,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { quillEditor } from 'vue-quill-editor';
import fileDownload from 'js-file-download';
import { title } from '@core/utils/filter';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';

// eslint-disable-next-line
import 'quill/dist/quill.core.css';
// eslint-disable-next-line
import 'quill/dist/quill.snow.css';
import AttendanceModal from '@/views/apps/roster/courses/sections/view/components/AttendanceModal.vue';

export default {
  components: {
    quillEditor,
    BButton,
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BCardText,
    BFormInput,
    AppTimeline,
    AppTimelineItem,
    AttendanceModal,
  },
  props: {
    courseSectionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('userData'));
    },
  },
  data: () => {
    return {
      isBtnBusy: false,
      isAttendanceOpen: false,
      accessCode: undefined,
      broadcastSubject: undefined,
      broadcastEmail: undefined,
    };
  },
  mounted() {
    this.broadcastEmail = `<p>Hello class,</p><br><p>{{ messageContent }}</p><br><p>Thank you,<br>${this.userData.name}</p>`;
  },
  watch: {
    // broadcastEmail() {
    //   console.log(this.broadcastEmail);
    // },
  },
  methods: {
    async downloadSectionExportSheet(mode) {
      this.isBtnBusy = true;

      console.log(this.courseSectionData);
      this.$store
        .dispatch('rosterStoreModule/exportSectionRoster', {
          id: this.courseSectionData.id,
          // queryParams: { mode: mode || 'standard' },
        })
        .then((response) => {
          this.isBtnBusy = false;

          const { data } = response;
          const name = `${this.resolveCourseSectionName(this.courseSectionData)} - ${this.courseSectionData.course.name}`;
          fileDownload(data, `Section Roster - ${name}.xlsx`);
        })
        .catch((error) => {
          this.isBtnBusy = false;
        });
    },
    async downloadSectionExportCompliance(mode) {
      this.isBtnBusy = true;

      console.log(this.courseSectionData);
      this.$store
        .dispatch('rosterStoreModule/exportSectionCompliance', {
          id: this.courseSectionData.id,
          // queryParams: { mode: mode || 'standard' },
        })
        .then((response) => {
          this.isBtnBusy = false;

          const { data } = response;
          const name = `${this.resolveCourseSectionName(this.courseSectionData)} - ${this.courseSectionData.course.name}`;
          fileDownload(data, `Section Compliance - ${name}.xlsx`);
        })
        .catch((error) => {
          this.isBtnBusy = false;
        });
    },
    async downloadAttendanceSheet(mode) {
      this.isBtnBusy = true;

      console.log(this.courseSectionData.courseCohort.id);
      this.$store
        .dispatch('rosterStoreModule/exportSectionAttendance', {
          id: this.courseSectionData.id,
          queryParams: { mode: mode || 'standard' },
        })
        .then((response) => {
          this.isBtnBusy = false;

          const { data } = response;
          const name = `${this.resolveCourseSectionName(this.courseSectionData)} - ${this.courseSectionData.course.name}`;
          fileDownload(data, `${name} Student Attendance (${title(mode)}).xlsx`);
        })
        .catch((error) => {
          this.isBtnBusy = false;
        });
    },
    publishDraft() {
      this.isBtnBusy = true;
      const pl = this.courseSectionData;
      store
        .dispatch('rosterStoreModule/updateCourseSection', { courseSectionId: pl.id, courseSectionData: { status: 'created' } })
        .then((response) => {
          this.isBtnBusy = false;

          this.$emit('refetch-data');

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Course Section Published!',
              icon: 'EditIcon',
              variant: 'success',
            },
          }).catch((error) => {
            this.isBtnBusy = false;
          });
        });
    },
    takeClassAttendance() {
      this.isAttendanceOpen = true;
    },
    sendEmailBroadcast(event) {
      event.preventDefault();

      console.log(event, this.broadcastSubject, this.broadcastEmail);

      if (!this.broadcastEmail || !this.broadcastSubject) {
        return;
      }

      this.$swal({
        title: 'Are you sure?',
        text: 'Your broadcast will be sent immediately after accepting. This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/sendCourseSectionBroadcast', {
              courseId: this.courseSectionData.id,
              broadcast: {
                accessCode: this.accessCode,
                subject: this.broadcastSubject,
                body: this.broadcastEmail,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                this.$refs['modal-wolfe-didnt-implement'].hide();

                this.$swal({
                  icon: 'success',
                  title: 'Sent!',
                  text: 'Your broadcast has been queued to be sent.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                });
              }
            })
            .catch((error) => {
              console.error(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to send broadcast',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response ? error.response.data.message : error.message,
                },
              });
            });
        }
      });
    },
  },
  setup(props) {
    const { resolveCourseSectionName } = useCourseSections();
    return {
      title,
      resolveCourseSectionName,
    };
  },
};
</script>

<style></style>
